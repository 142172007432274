import React,{ useState,useEffect } from "react";

import {
	connect
} from "react-redux";

import LoadingSpinner from "../components/UI/LoadingSpinner";

import { getUserLightStrandPatterns } from "../store/actions/lightStrand";

import * as api from "../api";

import { selectTimeArray } from "../data";

const LightStrand = (props) => {
	useEffect(() => {
		window.scrollTo(0,0)
	},[])

	const [loading,setLoading]																= useState(true);
	const [loadingLightStrand,setLoadingLightStrand]					= useState(true);
	const [loadingLightStrandMode,setLoadingLightStrandMode]	= useState(true);
	const [loadingUserLightStrandPatterns,setLoadingUserLightStrandPatterns]	= useState(true);

	const [lightStrandModes,setLightStrandModes]			= useState([]);

	const [name,setName]						= useState("");
	const [location,setLocation]		= useState("");
	const [mode,setMode]						= useState(null);
	const [startTime,setStartTime]	= useState("");
	const [endTime,setEndTime]			= useState("");

	const [timeInputDisabled,setTimeInputDisabled]	= useState(false);

	const lightStrandId = props.match.params._id;
	const [lightStrand,setLightStrand] = useState(null);

	const { userLightStrandPatterns, getUserLightStrandPatterns } = props;

	useEffect(() => {
		setLoading(true);
		setLoadingLightStrand(true);
		setLoadingLightStrandMode(true);

		api.getLightStrand(lightStrandId).then((result) => {
			setLightStrand(result);
		}).catch(error => {
			console.log(error);
		}).finally(() => {
			setLoadingLightStrand(false);
		});

		api.getLightStrandModes().then((result) => {
			setLightStrandModes(result);
		}).catch(error => {
			console.log(error);
		}).finally(() => {
			setLoadingLightStrandMode(false);
		});

		// api.getUserLightStrandPatterns().then((result) => {
		// 	setLightStrandPatterns(result);
		// }).catch(error => {
		// 	console.log(error);
		// }).finally(() => {
		// 	setLoadingLightStrandMode(false);
		// });

	},[lightStrandId,setLoading,setLightStrandModes,setLoadingLightStrandMode,]);

	useEffect(() => {
		setLoadingUserLightStrandPatterns(true);
		if (!userLightStrandPatterns) {
			getUserLightStrandPatterns();
		} else {
			setLoadingUserLightStrandPatterns(false);
		}
	},[userLightStrandPatterns,getUserLightStrandPatterns,setLoadingUserLightStrandPatterns]);

	useEffect(() => {
		if (!loadingLightStrand && !loadingLightStrandMode && !loadingUserLightStrandPatterns)
			setLoading(false);
	},[loadingLightStrand,loadingLightStrandMode,loadingUserLightStrandPatterns,setLoading])

	useEffect(() => {
		if (lightStrand && lightStrandModes) {

			setName(lightStrand.name);
			setLocation(lightStrand.location);

			setMode(lightStrand.mode);

			const tempStartTime = new Date(lightStrand.startTime);
			// console.log(tempEndTime);
			setStartTime(`${("0" + tempStartTime.getHours()).slice(-2)}:${("0" + tempStartTime.getMinutes()).slice(-2)}`)

			const tempEndTime = new Date(lightStrand.endTime);
			// console.log(tempEndTime.getHours());
			setEndTime(`${("0" + tempEndTime.getHours()).slice(-2)}:${("0" + tempEndTime.getMinutes()).slice(-2)}`);

			if (lightStrand.mode._id === "5f66e64bd6d08911133b2bc4" || lightStrand.mode._id === "5f66e64bd6d08911133b2bc5") // OFF or ON
				setTimeInputDisabled(false);
			else
				setTimeInputDisabled(true);

		}

	},[lightStrand,lightStrandModes]);

	const onNameChange = (e) => {
		// console.log(e.target.value);
		setName(e.target.value);
	}

	const onLocationChange = (e) => {
		// console.log(e.target.value);
		setLocation(e.target.value);
	}


	const onModeChange = (_id) => {
		// console.log(_id);
		setMode(lightStrandModes.find(lightStrandMode => lightStrandMode._id === _id))
		// TODO: Set disable
		if (_id === "5f66e64bd6d08911133b2bc4" || _id === "5f66e64bd6d08911133b2bc5") // OFF or ON
			setTimeInputDisabled(false);
		else
			setTimeInputDisabled(true);
	}

	const onStartTimeChange = (e) => {
		setStartTime(e.target.value);
	}

	const onEndTimeChange = (e) => {
		setEndTime(e.target.value);
	}

	const updateLightStrand = (e) => {
		e.preventDefault();

		const updatedStartTime = new Date();
		updatedStartTime.setHours(startTime.split(":")[0]);
		updatedStartTime.setMinutes(startTime.split(":")[1]);
		updatedStartTime.setSeconds(0);

		const updatedEndTime = new Date();
		updatedEndTime.setHours(endTime.split(":")[0]);
		updatedEndTime.setMinutes(endTime.split(":")[1]);
		updatedEndTime.setSeconds(0);

		const lightStrandData = {
			_id:				lightStrand._id,
			name:				name,
			location:		location,
			mode:				mode._id,
			startTime:	updatedStartTime.toUTCString(),
			endTime:		updatedEndTime.toUTCString()
		};

		try {
			api.updateLightStrand(lightStrand._id,lightStrandData);
			props.history.push("/");
		} catch (error) {
			console.log("Error caught");
			console.log(error);

			// setEmailError(false);
			// setPasswordError(false);
			// switch(error.message) {
			// 	case "INVALID_USER": {
			// 		setEmailError(true);
			// 		break;
			// 	} case "INVALID_PASSWORD": {
			// 		setPasswordError(true);
			// 		// setPasswordError("The u is incorrect.");
			// 		break;
			// 	} default: {

			// 	}
			// }
		}
	}

	if (loading || !lightStrand || !lightStrandModes || !mode || !props.userLightStrandPatterns)
		return <LoadingSpinner />;

	return (
		<div className="text-white">
			<div className="d-flex justify-content-center mb-5">
				<h1 className="text-white">Update LightStrands</h1>
			</div>

			<form onSubmit={updateLightStrand}>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="inputName">Name</label>
						<input type="text" className="form-control form-control-sm" id="inputName" defaultValue={name} onChange={onNameChange} />
					</div>

					<div className="form-group col-md-6">
						<label htmlFor="inputLocation">Location</label>
						<input type="text" className="form-control form-control-sm" id="inputLocation" defaultValue={location} onChange={onLocationChange} />
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="inputMode">Mode</label>
						<div className="btn-group btn-group-sm btn-group-toggle" id="inputMode" data-toggle="buttons">
							{
								lightStrandModes.map(lightStrandMode => (

									lightStrandMode.value === mode.value ?
									<label key={lightStrandMode.value} className="btn btn-secondary btn-dark active">
										<input type="radio" name="options" id={lightStrandMode.value} onClick={() => onModeChange(lightStrandMode._id)} defaultChecked/> {lightStrandMode.name}
									</label>
									:
									<label key={lightStrandMode.value} className="btn btn-secondary btn-dark">
										<input type="radio" name="options" id={lightStrandMode.value} onClick={() => onModeChange(lightStrandMode._id)} /> {lightStrandMode.name}
									</label>
								))
							}
							
						</div>
					</div>
				</div>

				{
					timeInputDisabled
					&&
					<div className="form-row">
						<div className="form-group col-md-6">
							<label htmlFor="inputStartTime">ON time</label>
							<select className="custom-select custom-select-sm" id="inputStartTime" defaultValue={startTime} onChange={onStartTimeChange}>
							{
								selectTimeArray.map(time => (
									<option
										key={time.value}
										value={time.value}
									>
										{time.text}
									</option>
								))
							}
							</select>
						</div>

						<div className="form-group col-md-6">
							<label htmlFor="inputEndTime">OFF time</label>
							<select className="custom-select custom-select-sm" id="inputEndTime" defaultValue={endTime} onChange={onEndTimeChange}>
							{
								selectTimeArray.map(time => (
									<option
										key={time.value}
										value={time.value}
									>
										{time.text}
									</option>
								))
							}
							</select>
						</div>
					</div>
				}

				<div className="mt-3">
					<button type="submit" className="btn btn-primary">Update</button>
				</div>

				{/* TODO: Add cancel/back button
				<div className="mt-3">
					<button type="submit" className="btn btn-primary">Cancel</button>
				</div>
				*/}

			</form>
		</div>
	);
};

export default connect(
	(state) => ({
		userLightStrandPatterns:	state.lightStrand.userLightStrandPatterns,
	}),
	{
		getUserLightStrandPatterns,
	}
)(LightStrand);