import React from "react";

const ColorPickerContextMenu = (props) => (
	<div
		style={{
			border:			"solid 1px #ccc",
			display:		"inline-block",
			margin:			"5px",
			background:	"#FFF",
			color:			"#000",
			fontFamily:	"sans-serif",
			cursor:			"pointer",
			fontSize:		"12px",
			position:		"absolute",
			top:				"15px",
			left:				`${props.coordinates.x}px`
		}}
		tabIndex={0}
	>
		{
			props.canAdd
			&&
			<div
				style={{
					borderBottom: "dotted 1px #ccc",
					padding: "5px 25px"
				}}
				onClick={props.handleAdd}
			>
				Add Color
			</div>
		}
		{
			props.canDelete
			&&
			<div
				style={{
					borderBottom: "dotted 1px #ccc",
					padding: "5px 25px"
				}}
				onClick={props.handleDelete}
			>
				Delete Color
			</div>
		}
		<div style={{padding: "5px 25px"}} onClick={props.handleContextMenuClose}>Close</div>
	</div>
);

export default ColorPickerContextMenu;