import React from 'react';
import ReactDOM from 'react-dom';

import {
	BrowserRouter
} from "react-router-dom";

import './index.css';

import App from './App';

import * as serviceWorker from "./serviceWorker";

import { Provider } from 'react-redux';
import { configureStore } from "./store";
import * as authenticationActions from "./store/actions/authentication";

import Navbar from './components/Navbar';
import Footer from './components/Footer';

const store = configureStore();

if (localStorage.userData) {
	const userData = JSON.parse(localStorage.userData)
	try {
		const expirationDate = new Date(userData.tokenExpirationDate);
		if (expirationDate <= new Date() || !userData.token || !userData.userId) {
			// Not authenticated; route to authentication screen
		}

		store.dispatch(
			authenticationActions.authenticate(
				userData.userId,
				userData.token,
				expirationDate.getTime() - new Date()
			)
		)
	} catch (e) {
		console.log(`Error dispatching local session authentication...${e}`);
	}
}

ReactDOM.render(
	<React.StrictMode>

		<Provider store={store}>

			<BrowserRouter>

				<div className="min-vh-100 d-flex flex-column justify-content-between">

					<Navbar />

					<App className="flex-grow-1" />

					<Footer className="mt-auto" />

				</div>

			</BrowserRouter>

		</Provider>

	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
