import React from "react";

import {
	Link
} from "react-router-dom";

// https://getbootstrap.com/docs/4.0/examples/sticky-footer/
// https://getbootstrap.com/docs/4.0/examples/sticky-footer/sticky-footer.css

const Footer = () => (
	<div className="container-fluid bg-dark text-center">
		<Link to="/">
			<img
				src="/images/swirl.png"
				className="rounded-circle my-3"
				style={{
					width:"50px",
					height:"50px"
				}}
				alt="swirl.png"
			/>
		</Link>

		<p className="text-center text-white small pb-2 mb-0">
			Copyright { (new Date()).getFullYear() } Techteia.  All rights reserved.
		</p>

	</div>
);

export default Footer;
