import React from "react";
import {
	Switch,
	Route,
	withRouter,
	Redirect,
} from "react-router-dom";

import {
	connect
	// useSelector,
} from "react-redux";

import "./App.css";

import LightStrand from "./screens/LightStrand";
import LightStrandPattern from "./screens/LightStrandPattern";

import ClaimLightStrands from "./screens/ClaimLightStrands";

import LightStrandPatternCreate from "./components/lightStrandPattern/LightStrandPatternCreate";
import LightStrandPatternUpdate from "./components/lightStrandPattern/LightStrandPatternUpdate";


import Home from "./components/Home";
import Authentication from "./screens/Authentication";

import withAuth from "./hocs/withAuth";

const AuthenticationRouter = () => (
	<div className="col-12 col-md-3 flex-grow-1 container text-white py-5 d-flex justify-content-center align-items-start">
		<Switch>
			<Route
				exact
				path="/login"
				component={Authentication}
			/>
			<Route
				exact
				path="/signup"
				component={Authentication}
			/>
			<Route
				render={() => <Redirect to="/login" />}
			/>
		</Switch>
	</div>
);

const LumenusRouter = () => (
	<div className="col-12 flex-grow-1 container text-white py-5 d-flex justify-content-center align-items-start">
		<Switch>
			<Route
				path="/lightStrands/:lightStrandId/lightStrandPatterns/create"
				component={withAuth(LightStrandPatternCreate)}
			/>

			<Route
				path="/lightStrands/:lightStrandId/lightStrandPatterns/update/:lightStrandPatternId"
				component={withAuth(LightStrandPatternUpdate)}
			/>

			<Route
				path="/lightStrandPatterns/:_id"
				component={withAuth(LightStrandPattern)}
			/>

			<Route
				path="/claimLightStrands"
				component={withAuth(ClaimLightStrands)}
			/>

			<Route
				exact
				path="/lightStrandPatterns"
				component={withAuth(Home)}
			/>

			<Route
				path="/lightStrands/:_id"
				component={withAuth(LightStrand)}
			/>

			<Route
				exact
				path="/lightStrands"
				component={withAuth(Home)}
			/>

			<Route
				exact
				path="/"
				component={withAuth(Home)}
			/>

			<Route
				render={() => <Redirect to="/" />}
			/>
		</Switch>
	</div>
);

const App = ({ authenticated }) => {
	return authenticated ? <LumenusRouter /> : <AuthenticationRouter />;
}

function mapStateToProps(state) {
	return {
		authenticated:	state.authentication.authenticated
	};
}

export default connect(
	mapStateToProps,
	{
		// getLightStrand,
		// logout
	}
)(withRouter(App));
