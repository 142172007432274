
const DEBUG						= process.env.REACT_APP_DEBUG !== undefined;
const BASE_PATH_API		= `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT ? process.env.REACT_APP_API_PORT : ''}`;
const BASE_PATH_AUTH	= `${process.env.REACT_APP_AUTH_PROTOCOL}://${process.env.REACT_APP_AUTH_HOST}:${process.env.REACT_APP_AUTH_PORT ? process.env.REACT_APP_API_PORT : ''}`;

// console.log(BASE_PATH_AUTH);

// const BASE_PATH_API	= 
// 	process.env.REACT_APP_API_PORT
// 	?
// 	`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`
// 	:
// 	`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


// const BASE_PATH_API	= 
// 	(
// 		process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "prod"
// 		||
// 		process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "production"
// 	)
// 	?
// 	`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`
// 	:
	// `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;


let authorizationToken = "";

const httpMethods = {
	GET:		"GET",
	POST:		"POST",
	PUT:		"PUT",
	DELETE:	"DELETE"
};

export const setAuthorizationToken = (token) => (
	authorizationToken = token ? `Bearer ${token}` : ""
)

const getAuthorizationToken = () => (authorizationToken);

const getFetchOptions = (method,withAuth,body = null) => (
	{
		method: method.toUpperCase(),
		headers: {
			"Content-Type": 'application/json',
			"Authorization": withAuth ? getAuthorizationToken() : null
		},
		body
	}
);


export const getLightStrandPatternTypes = async () => {
	if (DEBUG) console.log(`[API] getLightStrandPatternTypes()`);
	const response = await fetch(
		`${BASE_PATH_API}/api/patternTypes`,
		getFetchOptions(httpMethods.GET,false)
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

export const getLightStrandPattern = async (_id) => {
	if (DEBUG) console.log(`[API] getLightStrandPattern(_id=${_id})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrandPatterns/${_id}`,
		getFetchOptions(httpMethods.GET,true)
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

export const getLightStrandModes = async () => {
	if (DEBUG) console.log(`[API] getLightStrandModes()`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrandModes`,
		getFetchOptions(httpMethods.GET,false)
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

export const claimLightStrandPattern = async (userId,lightStrandPatternId) => {
	if (DEBUG) console.log(`[API] claimLightStrandPattern(userId=${userId},lightStrandPatternId=${lightStrandPatternId})`);

	return updateLightStrandPattern(
		lightStrandPatternId,
		{
			user: userId,
		}
	);
}

export const claimLightStrand = async (userId,lightStrandId) => {
	if (DEBUG) console.log(`[API] claimLightStrand(userId=${userId},lightStrandId=${lightStrandId})`);

	return updateLightStrand(
		lightStrandId,
		{
			user: userId,
		}
	);
}

export const getUnclaimedLightStrands = async () => {
	if (DEBUG) console.log(`[API] getUnclaimedLightStrands()`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrands/unclaimed`,
		getFetchOptions(httpMethods.GET,true)
	);


	if (!response.ok) {
		const error = await response.json();
		console.log("error in API meep");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		// throw Error(error.id);
		throw new Error(error.id);
		// return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

export const getUserLightStrands = async (userId) => {
	if (DEBUG) console.log(`[API] getUserLightStrands(userId=${userId})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrands/user/${userId}`,
		getFetchOptions(httpMethods.GET,true)
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API meep");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		// throw Error(error.id);
		throw new Error(error.id);
		// return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

export const getUserLightStrandPatterns = async (userId) => {
	if (DEBUG) console.log(`[API] getUserLightStrandPatterns(userId=${userId})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrandPatterns/user/${userId}`,
		getFetchOptions(httpMethods.GET,true)
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API meep");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		// throw Error(error.id);
		throw new Error(error.id);
		// return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

export const getLightStrand = async (_id) => {
	if (DEBUG) console.log(`[API] getLightStrand(_id=${_id})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrands/${_id}`,
		getFetchOptions(httpMethods.GET,true)
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		// throw new Error(error.id);
		// return { error: error };
		return null;
	}

	const responseData = await response.json();

	return responseData.result;
}

// Get all projects from DB (with limited information populated)
export const login = async (email,password) => {
	if (DEBUG) console.log(`[API] login(email=${email},password=${password})`);

	const response = await fetch(
		`${BASE_PATH_AUTH}/api/login`,
		getFetchOptions(httpMethods.POST,false,JSON.stringify({
			email,
			password
		}))
	);

	if (!response.ok) {
		const error = await response.json();

		throw new Error(error.id);
	}

	const responseData = await response.json();

	return responseData;
}

export const signup = async (email,password) => {
	if (DEBUG) console.log(`[API] signup(email=${email},password=${password})`);

	const response = await fetch(
		`${BASE_PATH_AUTH}/api/signup`,
		getFetchOptions(httpMethods.POST,false,JSON.stringify({
			email,
			password
		}))
	);

	if (!response.ok) {
		const error = await response.json();

		throw new Error(error.id);
	}

	const responseData = await response.json();

	return responseData;
}

export const createAndSetLightStrandPattern = async (lightStrandId,lightStrandPattern) => {
	if (DEBUG) console.log(`[API] createAndSetLightStrandPattern(lightStrandId=${lightStrandId},lightStrandPattern=${JSON.stringify(lightStrandPattern)})`);

	let response;
	// Create lightstrand pattern
	try {
		response = await createLightStrandPattern(lightStrandPattern);
	} catch(error) {
		console.log(error);
		throw new Error(error.message);
	}
	// console.log("response",response);
	const lightStrand = { pattern: response._id };
	// console.log("lightStrand",lightStrand);
	
	// console.log("Updating light strand...");
	// Set lightstrand pattern to lightstrand
	try {
		response = await updateLightStrand(lightStrandId,lightStrand);
	} catch(error) {
		console.log(error);
		throw new Error(error.message);
	}
	
	console.log(response);
	return response;
}

export const createLightStrandPattern = async (lightStrandPattern) => {
	if (DEBUG) console.log(`[API] createLightStrandPattern(lightStrandPattern=${lightStrandPattern})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrandPatterns`,
		getFetchOptions(httpMethods.POST,true,JSON.stringify({
				user: 				lightStrandPattern.user,
				name:					lightStrandPattern.name,
				description:	lightStrandPattern.description,
				type:					lightStrandPattern.type,
				brightness:		lightStrandPattern.brightness,
				delay:				lightStrandPattern.delay,
				colors:				lightStrandPattern.colors
		}))
	);

	// console.log(response);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		throw new Error(error.id);
		// return { error: error };
	}

	const responseData = await response.json();

	// console.log(responseData);
	return responseData;
}

export const updateLightStrandPattern = async (_id,lightStrandPattern) => {
	if (DEBUG) console.log(`[API] updateLightStrandPattern(_id=${_id},lightStrandPattern=${lightStrandPattern})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrandPatterns/${_id}`,
		getFetchOptions(httpMethods.PUT,true,JSON.stringify(lightStrandPattern))
	);

	// console.log(response);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		throw new Error(error.id);
		// return { error: error };
	}

	const responseData = await response.json();

	// console.log(responseData);
	return responseData;
}

export const updateLightStrand = async (_id,lightStrand) => {
	if (DEBUG) console.log(`[API] updateLightStrand(_id=${_id},lightStrand=${lightStrand})`);

	const response = await fetch(
		`${BASE_PATH_API}/api/lightStrands/${_id}`,
		getFetchOptions(httpMethods.PUT,true,JSON.stringify(lightStrand))
	);

	if (!response.ok) {
		const error = await response.json();
		console.log("error in API");
		console.log(error);
		// const errorId = error.id;
		// console.log(errorId);
		// let message;
		// switch(error.id) {
		// 	case 'INVALID_USER':
		// 		message = 'Email not found...';
		// 		break;
		// 	case 'INVALID_PASSWORD':
		// 		message = 'Password is invalid...';
		// 		break;
		// 	default:
		// 		message = 'Something went wrong...';
		// 		break;
		// }
		// throw error;
		throw new Error(error.id);
		// return { error: error };
	}

	const responseData = await response.json();

	return responseData;
}