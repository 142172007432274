import React,{ useState,useEffect } from "react";

import {
	Link,
	NavLink,
	useHistory
} from "react-router-dom";

import {
	connect,
} from "react-redux";

import { logout } from "../store/actions/authentication";

import { getUnclaimedLightStrands } from "../store/actions/lightStrand";

import LoadingSpinner from "./UI/LoadingSpinner";

const Navbar = (props) => {
	const history								= useHistory();
	const [loading,setLoading]	= useState(false);

	const { authenticated,getUnclaimedLightStrands } = props;

	useEffect(() => {
		if (authenticated) {
			setLoading(true);
			try {
				getUnclaimedLightStrands();
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}
	},[authenticated,getUnclaimedLightStrands,setLoading])

	const logout = () => {
		props.logout();
		history.push("/login");
	};

	if (loading) {
		return (
			<LoadingSpinner />
		);
	}

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark"> 

			<Link className="navbar-brand" to="/">
				<img
					src="/images/swirl.png"
					width="50"
					height="50"
					className="d-inline-block align-top rounded-circle"
					alt="swirl.png"
				/>
			</Link>

			<Link className="navbar-brand" to="/">
				<span style={{color: '#84AABA'}}>Lumenus</span>
			</Link>

			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarToggler"
				aria-controls="navbarToggler"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>

			{
			props.authenticated
			&&
			<NavbarMenu
				logout={logout}
				unclaimedLightStrands={props.unclaimedLightStrands}
			/>
			}
		</nav>
	);
};

const NavbarMenu = (props) => (
	<div
		id="navbarToggler"
		className="collapse navbar-collapse"
	>

		<ul className="navbar-nav mr-auto mt-2 mt-lg-0">

			<li className="nav-item">
				<NavLink
					exact
					className="nav-link p-3"
					activeClassName="active"
					to="/"
				>
					My LightStrands
				</NavLink>
			</li>

			{
			props.unclaimedLightStrands.length > 0
			&&
			<li className="nav-item">
				<NavLink
					exact className="nav-link p-3"
					activeClassName="active"
					to="/claimLightStrands"
				>
					Claim LightStrands
				</NavLink>
			</li>
			}

		</ul>

		<ul className="navbar-nav ml-auto mt-2 mt-lg-0">

			<li className="nav-item">
				<div
					className="nav-link p-3"
					style={{ cursor: "pointer" }}
					onClick={props.logout}
				>
					Log out
				</div>
			</li>

		</ul>

</div>
);

export default connect(
	(state) => ({
		authenticated:					state.authentication.authenticated,
		unclaimedLightStrands:	state.lightStrand.unclaimedLightStrands,
	}),
	{
		logout,
		getUnclaimedLightStrands
	}
)(Navbar);
