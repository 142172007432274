import React,{ useState,useEffect } from "react";

// import {
// 	// useHistory,
// 	// Redirect,
// 	Link
// } from "react-router-dom";

import {
	connect,
} from "react-redux";
// import { logout } from "../store/actions/authentication";
import { getUnclaimedLightStrands,claimLightStrand,claimLightStrandPattern } from "../store/actions/lightStrand";
// import * as errorActions from "../store/actions/error";

import LoadingSpinner from "../components/UI/LoadingSpinner";

const ClaimLightStrands = (props) => {
	useEffect(() => {
		window.scrollTo(0,0)
	},[]);

	const [loading,setLoading] = useState(true);

	const { getUnclaimedLightStrands } = props;

	useEffect(() => {
		setLoading(true);
		try {
			getUnclaimedLightStrands();
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	},[setLoading,getUnclaimedLightStrands]);

	const claimLightStrandHandler = (lightStrandId,lightStrandPatternId) => {
		console.log(`User (${props.userId}) is claiming LightStrand (${lightStrandId})`);
		props.claimLightStrand(props.userId,lightStrandId);
		props.claimLightStrandPattern(props.userId,lightStrandPatternId);
		getUnclaimedLightStrands();
		if (props.unclaimedLightStrands.length <= 1) {
			props.history.push("/");
		}
	}

	if (loading)
		return <LoadingSpinner />;

	if (!props.unclaimedLightStrands.length) {
		return (
			<React.Fragment>
				<p className="text-white">No unclaimed LightStrands...</p>
			</React.Fragment>
		);
	}

	return (
		<div>
			<div className="d-flex justify-content-center mb-5">
				<h1 className="text-white">Unclaimed LightStrands</h1>
			</div>
			<div className="">
				{
					props.unclaimedLightStrands.map(lightStrand => (
						<div key={lightStrand._id} className="text-white mt-5">
							<div className="card bg-dark text-center">
								<img src="/images/lumenus_strand_horizontal.png" className="card-img" alt="lumenus_strand_horizontal.png" />
								<div className="card-img-overlay d-flex flex-column justify-content-center">

									<div>
										<h2 className="card-title">{lightStrand.name}</h2>
									</div>

									<div>
										<p className="card-text">{lightStrand.location}</p>
									</div>

									<div>
										<p className="card-text">{lightStrand.macAddress}</p>
									</div>

									<div className="mt-3">
										<button className="btn btn-primary" onClick={() => claimLightStrandHandler(lightStrand._id,lightStrand.pattern._id)}>Claim LightStrand</button>
									</div>

								</div>

							</div>
						</div>
					))
				}
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		// error:									state.error,
		userId:									state.authentication.userId,
		unclaimedLightStrands:	state.lightStrand.unclaimedLightStrands,
	};
}

export default connect(
	mapStateToProps,
	{
		getUnclaimedLightStrands,
		claimLightStrand,
		claimLightStrandPattern
	}
)(ClaimLightStrands);

// <LightStrandCard key={lightStrand._id} lightStrand={lightStrand} />
