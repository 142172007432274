import * as actions from "../actions";

// if (localStorage.userLightStrands) {
// 	const userLightStrands = JSON.parse(localStorage.userLightStrands)
// 	console.
// // 	// console.log("authenticated by session!");
// // 	// try {
// // 	// 	store.dispatch(authenticationActions.authenticate(userLightStrands.userId,userLightStrands.token,900000))
// // 	// } catch (e) {
// // 	// 	console.log(`Error dispatching local session authentication...${e}`);
// // 	// }
// }

const initialState = {
	lightStrands:							[],
	// userLightStrands:				localStorage.userLightStrands ? JSON.parse(localStorage.userLightStrands) : [],
	userLightStrands:					[],
	selectedLightStrand:			null,
	unclaimedLightStrands:		[],

	userLightStrandPatterns:	[],
};

export default (state = initialState,action) => {
	switch (action.type) {
		case actions.GET_USER_LIGHTSTRANDS:
			return {
				...state,
				userLightStrands:	action.userLightStrands,
			};
		case actions.GET_SELECTED_LIGHTSTRAND:
			return {
				...state,
				selectedLightStrand: action.selectedLightStrand
			};
		case actions.GET_UNCLAIMED_LIGHTSTRANDS:
			return {
				...state,
				unclaimedLightStrands:	action.unclaimedLightStrands,
			};
		case actions.GET_USER_LIGHTSTRAND_PATTERNS:
			return {
				...state,
				userLightStrandPatterns:	action.userLightStrandPatterns,
			};
		default:
			return state;
	}
}