import { createStore, applyMiddleware } from "redux";
// import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

export function configureStore() {
	const store = createStore(
		rootReducer,
		applyMiddleware(thunk)
	);
	return store;
}

// export function configureStore() {
// 	const store = createStore(
// 		rootReducer,
// 		compose(
// 			applyMiddleware(thunk),
// 			window.devToolsExtension ? window.devToolsExtension() : f => f
// 		)
// 	);
// 	return store;
// }