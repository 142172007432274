import React from "react";

export default (props) => (
	<div className="d-flex vh-100 align-items-center justify-content-center">
		<div className="d-flex justify-content-center">
			<div className="spinner-border text-white" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	</div>
);