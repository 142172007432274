import React,{ useState } from "react";

import {
	connect
} from "react-redux";

import { useForm } from "react-hook-form";

import { signup } from "../../store/actions/authentication";

import { removeError } from "../../store/actions/error";

const Signup = (props) => {
	const [authenticating,setAuthenticating]	= useState(false);
	const { register,handleSubmit,errors }		= useForm();

	const onLogin = () => props.history.push("/login");

	const onSubmit = async (data,e) => {
		e.preventDefault();
		setAuthenticating(true);
		try {
			var loggedIn = await props.signup(data.email,data.password);
		} catch (error) {
			console.log(error);
		} finally {
			if (!loggedIn) setAuthenticating(false);
		}
	};

	return (
		<div className="card">
			<div className="card-body">

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group">
						<label className="font-weight-bold text-dark" htmlFor="email">Email</label>
						<div className="input-group input-group-lg">
							<input
								id="email"
								name="email"
								type="text"
								className="form-control"
								aria-label="Email"
								aria-describedby="basic-addon"

								ref={
									register({
										required: "Email cannot be empty",
										pattern: {
											value:		/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message:	"The email is not a valid email address."
										},
									})
								}
							/>
						</div>
						{
							errors.email
							&&
							<label className="text-danger small m-0 text-wrap">{errors.email.message}</label>
						}
					</div>

					<div className="mb-1">
						<label className="font-weight-bold text-dark" htmlFor="password">Password</label>
						<div className="input-group input-group-lg">
							<input
								id="password"
								name="password"
								type="password"
								className="form-control"
								aria-label="Password"
								aria-describedby="basic-addon"

								ref={
									register({
										required:	"Password cannot be empty",
										pattern: {
											value:		/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/,
											message:	"The password does not meet the required criteria."
										}
									})
								}
							/>
						</div>
						<div>
							<label className="text-muted small m-0 text-wrap">
								Password must be at least 8 characters, contain at least 1 uppercase letter, 1 lowercase letter, and 1 number
							</label>
						</div>
						<div>
						{
							errors.password
							&&
							<label className="text-danger small m-0 text-wrap">{errors.password.message}</label>
						}
						</div>
					</div>

					<div>
						{
							props.error.id
							&&
							<label className="text-danger small m-0 text-wrap">{ props.error.message }</label>
						}
					</div>

					{
						authenticating
						?
						<div className="input-group input-group-lg mt-3 mb-2" disabled>
							<button type="submit" className="btn btn-dark btn-block">
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
									Signing up...
							</button>
						</div>
						:
						<div className="input-group input-group-lg mt-3 mb-2">
							<button type="submit" className="btn btn-dark btn-block">Sign up</button>
						</div>
					}

				</form>

				<div className="d-flex justify-content-center text-dark">
					<small>Already have an account? <span className="btn-link text-decoration-none cursor-pointer" style={{ cursor: "pointer" }} onClick={onLogin}>Log in</span></small>
				</div>

			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		error:	state.error,
		authenticated:	state.authentication.authenticated,
	}),
	{
		signup,
		removeError
	}
)(Signup);