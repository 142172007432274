import React from "react";

import {
	Link
} from "react-router-dom";

const formatTime = (datetime) => {
	const localDatetime = new Date(datetime);
	const datetimeString = `${localDatetime.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}`;
	return datetimeString;
};

export const SettingsButton = ({ strandId }) => (
	<div className="position-relative float-right btn btn-outline-light border-0" style={{ zIndex: 5000 }}>
		<svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-gear-fill box-shadow" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
		</svg>
		<Link to={`/lightStrands/${strandId}`} className="stretched-link" />
	</div>
);

const modeToBadgeColor = {
	on: 'success',
	off: 'danger',
	scheduled: 'primary',
};

export const ModeBadge = ({ mode, startTime, endTime }) => {

	const startTimeString = formatTime(startTime);
	const endTimeString = formatTime(endTime);
	const scheduledString = `${startTimeString} - ${endTimeString}`;

	const badgeType = mode.name.toLowerCase();
	const badgeColor = modeToBadgeColor[badgeType];

	return (
		<span className={`badge badge-pill badge-${badgeColor}`}>
			{badgeType === 'on' && <span>ON</span> }
			{badgeType === 'off' && <span>OFF</span> }
			{badgeType === 'scheduled' && <span>{scheduledString}</span> }
		</span>
	);
};

const getColorString = (color) => {
	const r = parseInt(color.substring(0,2),16);
	const g = parseInt(color.substring(2,4),16);
	const b = parseInt(color.substring(4,6),16);
	return `rgb(${r},${g},${b})`;
};

export const PatternPreview = ({ pattern }) => {
	let { type, colors } = pattern;

	if (type.name === 'Rainbow' || type.name === 'Rainbow Chase' ) {
		colors = ["ff0000","ffa500","ffff00","008000","0000ff","4b0082","ee82ee"];
	}

	return (
		<div
			id="inputPatternType"
			className={`btn-group d-flex ${colors.length > 6 ? 'justify-content-between' : ''} flex-wrap p-2 rounded bg-light`}
		>
			{colors.map((color,index) => (
				<div
					key={index}
					className='rounded shadow-dark mx-2'
					style={{
						width:				'35px',
						height:				'20px',
						background:		getColorString(color),
					}}
				/>
			))}
		</div>
	);
};

export const LightStrandCardOverlay = ({ lightStrand }) => {

	const {
		name,
		mode,
		location,
		pattern,
		startTime,
		endTime,
		macAddress,
		firmwareVersion,
		dateUpdated,
	} = lightStrand;

	return (
		<div className="card-img-overlay p-0 d-flex flex-column justify-content-between">

			<div className='p-3'>
				<SettingsButton strandId={lightStrand._id} />

				<h2 className="card-title d-flex">{name}</h2>

				<div className="d-flex align-items-center">
					<div className="card-text mr-2">
						{location}
					</div>
					<ModeBadge
						mode={mode}
						startTime={startTime}
						endTime={endTime}
					/>

				</div>

				<div className="card-text mr-2 badge badge-pill badge-info">
					{macAddress} - v{firmwareVersion}
				</div>

				<div className="card-text mr-2 badge badge-pill badge-info">
					Last Updated: {(new Date(dateUpdated)).toLocaleString()}
				</div>
			</div>

			<div>
				<div className="px-3 py-2 d-flex align-items-center justify-content-between">
					<span className="px-2 py-1 m-1 badge badge-pill badge-light">{pattern.name}</span>
					<span className="px-2 py-1 m-1 badge badge-pill badge-primary">{pattern.type.name}</span>
				</div>

				<PatternPreview pattern={pattern} />

			</div>
		</div>
	);
}

export default ({
	lightStrand,
}) => {
	return (
		<div className="text-white mt-5">

			<div className="card bg-dark text-white">
				<img
					src="/images/lumenus_strand_horizontal.png"
					className="card-img"
					alt="lumenus_strand_horizontal.png"
				/>
				<LightStrandCardOverlay
					lightStrand={lightStrand}
				/>

				<Link
					to={`/lightStrands/${lightStrand._id}/lightStrandPatterns/update/${lightStrand.pattern._id}`}
					className="stretched-link"
				/>

			</div>
		</div>
	);
}
