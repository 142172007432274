import * as actions from "../actions";

import { errorMap } from "../../data";

const initialState = {
	id:				null,
	message:	null,
};

export default (state = initialState,action) => {
	switch (action.type) {
		case actions.ADD_ERROR:
			return {
				id:				action.id.toUpperCase(),
				message:	errorMap[action.id.toUpperCase()] ? errorMap[action.id.toUpperCase()] : "Unhandled error occurred!"
			};
		case actions.REMOVE_ERROR:
			return initialState;
		default:
			return state;
	}
}