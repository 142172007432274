import React,{ useState,useEffect } from "react";

import {
	connect
} from "react-redux";

import LoadingSpinner from "../UI/LoadingSpinner";

import ColorPicker from "../color/ColorPicker";

import * as api from "../../api";

const MAX_COLORS = 10;

const LightStrandPatternCreate = (props) => {

	const [loading,setLoading]						= useState(false);

	const [patternTypes,setPatternTypes]	= useState([]);

	const [name,setName]									= useState("");
	const [description,setDescription]		= useState("");
	const [brightness,setBrightness]			= useState(100);
	const [delay,setDelay]								= useState(50);
	const [patternType,setPatternType]		= useState("5f56a0263604913c339d863d");
	const [colors,setColors]							= useState([{
		color:		"FFFFFF",
		display:	false
	}]);

	const [delayDisplay,setDelayDisplay]		= useState(false);
	const [colorsDisplay,setColorsDisplay]	= useState(false);

	useEffect(() => {
		setLoading(true);

		api.getLightStrandPatternTypes().then((result) => {
			setPatternTypes(result);
		}).catch(error => {
			console.log(error);
		}).finally(() => {
			setLoading(false);
		});
	},[setLoading]);

	const onNameChange = (e) => setName(e.target.value)

	const onDescriptionChange = (e) => setDescription(e.target.value)

	const onBrightnessChange = (e) => setBrightness(e.target.value)

	const onDelayChange = (e) => setDelay(e.target.value)

	const onPatternTypeChange = (_id) => {
		setPatternType((patternTypes.find(patternType => patternType._id === _id))._id)
		setDelayDisplay(toggleDelayDisplay(_id));
		setColorsDisplay(toggleColorsDisplay(_id));
	}

	const toggleDelayDisplay = (_id) => {
		return (_id === "5f56a0263604913c339d863d") ? false : true; // Static
	}

	const toggleColorsDisplay = (_id) => {
		return (_id === "5f56a0263604913c339d8644" || _id === "5f56a0263604913c339d8645") ? false : true; // Rainbow, Rainbow Chase
	}

	const handleColorClick = (index) => {
		const updatedColors = [...colors];
		updatedColors[index].display = !colors[index].display;
		setColors(updatedColors);
	};

	const handleColorClose = (index) => {
		const updatedColors = [...colors];
		updatedColors[index].display = false;
		setColors(updatedColors);
	};

	const handleColorChange = (color,index) => {
		const updatedColors = [...colors];
		updatedColors[index].color = color.hex.slice(1);
		setColors(updatedColors);
	};

	const handleColorAdd = (index) => {
		let tempColors = [...colors];
		tempColors.splice(index,0,{...colors[index]});
		setColors(tempColors);
	}

	const handleColorDelete = (index) => setColors([...colors.slice(0,index),...colors.slice(index+1,colors.length)])

	const submitHandler = async (e) => {
		e.preventDefault();

		const lightStrandPatternData = {
			user:						props.userId,
			name:						name,
			description:		description,
			brightness:			brightness,
			delay:					toggleDelayDisplay(patternType) ? delay : 10,
			colors:					toggleColorsDisplay(patternType) ? colors.map(color => { return color.color }) : ["FFFFFF"],
			type:						patternType,
		};

		try {
			await api.createAndSetLightStrandPattern(props.match.params.lightStrandId,lightStrandPatternData);
			props.history.push("/");
		} catch (error) {
			console.log("Error caught");
			console.log(error);
		}
	}

	if (loading || !patternTypes)
		return <LoadingSpinner />;

	return (
		<div className="text-white">
			<div className="d-flex justify-content-center mb-5">
				<h1 className="text-white">Create LightStrand Pattern</h1>
			</div>

			<form onSubmit={submitHandler}>
				<div className="form-row">
					<div className="form-group col-md-4">
						<label htmlFor="inputName">Name</label>
						<input type="text" className="form-control" id="inputName" defaultValue={name} onChange={onNameChange} />
					</div>
					<div className="form-group col-md-8">
						<label htmlFor="inputDescription">Description</label>
						<input type="text" className="form-control" id="inputDescription" defaultValue={description} onChange={onDescriptionChange} />
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputPatternType">Pattern Type</label>
						<div className="btn-group d-block btn-group-toggle flex-wrap" id="inputPatternType" data-toggle="buttons">
							{
								patternTypes.map((pattern,index) => (

									index === 0 ?
									<label key={pattern.value} className="btn btn-dark px-2 rounded active">
										<input type="radio" name="options" id={pattern.value} onClick={() => onPatternTypeChange(pattern._id)} defaultChecked/><small>{pattern.name}</small>
									</label>
									:
									<label key={pattern.value} className="btn btn-dark px-2 rounded">
										<input type="radio" name="options" id={pattern.value} onClick={() => onPatternTypeChange(pattern._id)} /><small>{pattern.name}</small>
									</label>
								))
							}
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputBrightness">Brightness</label>
						<div className="d-flex justify-content-left align-items-center">
						<input type="range" className="custom-range inline-flex flex-start" min="0" max="255" step="5" id="inputBrightness" value={brightness} onChange={onBrightnessChange}/>
						<span className="font-weight-bold text-white inline-flex pl-2">{brightness}</span>
						</div>
					</div>
				</div>

				{
				delayDisplay
				&&
				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputDelay">Delay</label>
						<div className="d-flex justify-content-left align-items-center">
						<input type="range" className="custom-range inline-flex flex-start" min="10" max="1000" step="10" id="inputDelay" value={delay} onChange={onDelayChange}/>
						<span className="font-weight-bold text-white inline-flex pl-2">{delay}&nbsp;ms</span>
						</div>
					</div>
				</div>
				}

				{
				colorsDisplay
				&&
				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputPatternType">Colors</label>
						<div
							id="inputPatternType"
							className="btn-group btn-group-sm d-flex justify-content-between flex-wrap p-1"
							data-toggle="buttons"
							style={{
								background: "#fff",
								borderRadius: "2px",
							}}
						>
							{
								colors.map((color,index) => (
									<ColorPicker
										key={index}
										index={index}
										color={color}
										handleColorClick={handleColorClick}
										handleColorClose={handleColorClose}
										handleColorChange={handleColorChange}
										handleColorAdd={handleColorAdd}
										handleColorDelete={handleColorDelete}
										canAdd={colors.length < MAX_COLORS ? true : false}
										canDelete={colors.length > 1 ? true : false}
									/>
								))
							}
						</div>
					</div>
				</div>
				}

				<div className="mt-3">
					<button type="submit" className="btn btn-primary">Create</button>
				</div>

			</form>

		</div>
	);
};

export default connect(
	(state) => ({
		userId:	state.authentication.userId,
	}),
	{
	}
)(LightStrandPatternCreate);