import React from "react";
import { connect } from "react-redux";

export default function withAuth(ComponentToBeRendered) {
	class Authenticate extends React.Component {

		componentDidMount() {
			if (!this.props.authenticated)
				this.props.history.push("/login");
		}

		componentDidUpdate(nextProps) {
			if (!nextProps.authenticated)
				this.props.history.push("/login");
		}

		render() {
			return <ComponentToBeRendered {...this.props} />;
		}
	}

	return connect(
		(state) => ({
			authenticated: state.authentication.authenticated,
		})
	)(Authenticate);
}