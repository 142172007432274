import React,{ useState } from "react";

import {
	connect
} from "react-redux";

import { useForm } from "react-hook-form";

import { login } from "../../store/actions/authentication";

const Login = (props) => {
	const [authenticating,setAuthenticating]	= useState(false);
	const { register,handleSubmit,errors }		= useForm();

	const onSignup	= () => props.history.push("/signup");

	const onSubmit	= async (data,e) => {
		e.preventDefault();
		setAuthenticating(true);
		try {
			var loggedIn = await props.login(data.email,data.password);
		} catch (error) {
			console.log(error);
		} finally {
			if (!loggedIn) setAuthenticating(false);
		}
	};

	return (
		<div className="card">
			<div className="card-body">

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group">
						<label className="font-weight-bold text-dark" htmlFor="email">Email</label>
						<div className="input-group input-group-lg">
							<input
								id="email"
								name="email"
								type="text"
								className="form-control"
								aria-label="Email"
								aria-describedby="basic-addon1"
								ref={
									register({
										required: "Email cannot be empty",
									})
								}
							/>
						</div>
						{
							errors.email
							&&
							<label className="text-danger small m-0 text-wrap">{errors.email.message}</label>
						}
					</div>

					<div className="form-group">
						<label className="font-weight-bold text-dark" htmlFor="password">Password</label>
						<div className="input-group input-group-lg">
							<input
								id="password"
								name="password"
								type="password"
								className="form-control"
								aria-label="Password"
								aria-describedby="basic-addon1"
								ref={
									register({
										required:	"Password cannot be empty",
									})
								}
							/>
						</div>
						{
							errors.password
							&&
							<label className="text-danger small m-0 text-wrap">{errors.password.message}</label>
						}
					</div>

					<div>
						{
							props.error.id
							&&
							<label className="text-danger small m-0 text-wrap">{ props.error.message }</label>
						}
					</div>

					{
						authenticating
						?
						<div className="input-group input-group-lg mt-3 mb-2" disabled>
							<button type="submit" className="btn btn-dark btn-block">
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
									Logging in...
							</button>
						</div>
						:
						<div className="input-group input-group-lg mt-3 mb-2">
							<button type="submit" className="btn btn-dark btn-block">Log in</button>
						</div>
					}


				</form>

				<div className="d-flex justify-content-center text-dark">
					<small>Don't have an account? <span className="btn-link text-decoration-none cursor-pointer" style={{ cursor: "pointer" }} onClick={onSignup}>Sign up</span></small>
				</div>

			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		error:					state.error,
		authenticated:	state.authentication.authenticated,
	}),
	{
		login,
	}
)(Login);


// <form>
//   <div class="form-group">
//     <label for="exampleInputEmail1">Email address</label>
//     <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
//     <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
//   </div>
//   <div class="form-group">
//     <label for="exampleInputPassword1">Password</label>
//     <input type="password" class="form-control" id="exampleInputPassword1">
//   </div>
//   <div class="form-group form-check">
//     <input type="checkbox" class="form-check-input" id="exampleCheck1">
//     <label class="form-check-label" for="exampleCheck1">Check me out</label>
//   </div>
//   <button type="submit" class="btn btn-primary">Submit</button>
// </form>