import React,{ useState } from "react";

import { SketchPicker } from "react-color"; // https://casesandberg.github.io/react-color/

import ColorPickerContextMenu from "./ColorPickerContextMenu";

const ColorPicker = (props) => {
	// use state boolean to show context menu
	const [displayContextMenu,setDisplayContextMenu]	= useState(false);
	const [coordinates,setCoordinates]								= useState({ x: 0,y: 0 });

	const handleContextMenu = (e) => {
		e.preventDefault();
		setCoordinates({
			x:+e.offsetX,
			y:+e.offsetY
		});

		setDisplayContextMenu(true);
	}

	const handleAdd = () => {
		props.handleColorAdd(props.index);
		setDisplayContextMenu(false);
	}

	const handleDelete = () => {
		props.handleColorDelete(props.index);
		setDisplayContextMenu(false);
	}

	const handleContextMenuClose = (e) => {
		setDisplayContextMenu(false);
	}

	return (
		<div
			className="inline-flex m-2"
			onContextMenu={handleContextMenu}
		>
			<div
				className="d-flex"
				style={{
					display: 'inline-block',
					cursor: 'pointer'
				}}
				onClick={ () => props.handleColorClick(props.index) }
			>
				<div
					style={{
						width:				'35px',
						height:				'20px',
						borderStyle:	"solid",
						borderColor:	"grey",
						borderRadius:	'1px',
						background:		`rgb(${parseInt(props.color.color.substring(0,2),16)},${parseInt(props.color.color.substring(2,4),16)},${parseInt(props.color.color.substring(4,6),16)})`
					}}
				/>
				
			</div>
			{
				props.color.display
				&&
				<div
					style={{
						position: 'absolute',
						zIndex: '2'
					}}
				>
					<div
						style={{
							position:	'fixed',
							top:			'0px',
							right:		'0px',
							bottom:		'0px',
							left:			'0px'
						}}
						onClick={ () => props.handleColorClose(props.index) }
					/>
					<SketchPicker color={ props.color.color } onChange={ (color) => props.handleColorChange(color,props.index) } disableAlpha={true} />
				</div>
			}
			{
				displayContextMenu
				&&
				<ColorPickerContextMenu
					style={{ position: 'absolute',zIndex: '2' }}
					handleAdd={handleAdd}
					handleDelete={handleDelete}
					handleContextMenuClose={handleContextMenuClose}
					coordinates={coordinates}
					canAdd={props.canAdd}
					canDelete={props.canDelete}
				/>
			}
		</div>
	);
};

export default ColorPicker;