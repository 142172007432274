import * as actions from "../actions";

const initialState = {
	authenticated:	false,
	userId:					null,
	token:					null,
};

export default (state = initialState,action) => {
	switch (action.type) {
		case actions.AUTHENTICATE:
			return {
				authenticated:	action.authenticated,
				userId:					action.userId,
				token:					action.token,
			};
		case actions.LOGOUT:
			return initialState;
		default:
			return state;
	}
}