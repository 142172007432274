import React,{ useState,useEffect } from "react";

import {
	connect,
} from "react-redux";

import LoadingSpinner from "../UI/LoadingSpinner";

import ColorPicker from "../color/ColorPicker";

import * as api from "../../api";

import { getUserLightStrandPatterns } from "../../store/actions/lightStrand";

const MAX_COLORS = 10;

const LightStrandPatternUpdate = (props) => {
	const [loading,setLoading]																								= useState(true);
	const [loadingLightStrandPattern,setLoadingLightStrandPattern]						= useState(true);
	const [loadingPatternTypes,setLoadingPatternTypes]												= useState(true);
	const [loadingUserLightStrandPatterns,setLoadingUserLightStrandPatterns]	= useState(true);

	const [lightStrandPattern,setLightStrandPattern]	= useState(null);
	const [patternTypes,setPatternTypes]							= useState([]);

	const [name,setName]								= useState("");
	const [description,setDescription]	= useState("");
	const [brightness,setBrightness]		= useState(0);
	const [delay,setDelay]							= useState(0);
	const [patternType,setPatternType]	= useState("");
	const [colors,setColors]						= useState([]);

	const [delayDisplay,setDelayDisplay]		= useState(true);
	const [colorsDisplay,setColorsDisplay]	= useState(true);

	const { getUserLightStrandPatterns } = props;

	useEffect(() => {
		if (props.match.params.lightStrandPatternId) {
			setLoading(true);
			setLoadingLightStrandPattern(true);
			setLoadingPatternTypes(true);

			api.getLightStrandPattern(props.match.params.lightStrandPatternId).then((result) => {
				setLightStrandPattern(result);
			}).catch(error => {
				console.log(error);
			}).finally(() => {
				setLoadingLightStrandPattern(false);
			});

			api.getLightStrandPatternTypes().then((result) => {
				setPatternTypes(result);
			}).catch(error => {
				console.log(error);
			}).finally(() => {
				setLoadingPatternTypes(false);
			});
		}
	},[props.match.params.lightStrandPatternId]);

	useEffect(() => {
		// console.log(userLightStrandPatterns.length);
		setLoadingUserLightStrandPatterns(true);
		// if (userLightStrandPatterns && !userLightStrandPatterns.length) {
			// console.log("hit")
		try {
			getUserLightStrandPatterns();
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingUserLightStrandPatterns(false);
		}
		// } else {
			// setLoadingUserLightStrandPatterns(false);
		// }
	},[getUserLightStrandPatterns,setLoadingUserLightStrandPatterns]);


	useEffect(() => {
		if (!loadingLightStrandPattern || !loadingPatternTypes || !loadingUserLightStrandPatterns)
			setLoading(false);
	},[loadingLightStrandPattern,loadingPatternTypes,loadingUserLightStrandPatterns])

	useEffect(() => {
		if (lightStrandPattern) {
			setName(lightStrandPattern.name);
			setDescription(lightStrandPattern.description);
			setBrightness(lightStrandPattern.brightness);
			setDelay(lightStrandPattern.delay);
			setPatternType(lightStrandPattern.type._id);
			setColors(lightStrandPattern.colors.map(color => ({
				color:		color,
				display:	false
			})));

			setDelayDisplay(toggleDelayDisplay(lightStrandPattern.type._id));
			setColorsDisplay(toggleColorsDisplay(lightStrandPattern.type._id));
		}
	},[lightStrandPattern]);

	const onNameChange				= (e) => setName(e.target.value)

	const onDescriptionChange	= (e) => setDescription(e.target.value)

	const onBrightnessChange	= (e) => setBrightness(e.target.value)

	const onDelayChange				= (e) => setDelay(e.target.value)

	const onPatternTypeChange = (_id) => {
		setPatternType((patternTypes.find(patternType => patternType._id === _id))._id)
		setDelayDisplay(toggleDelayDisplay(_id));
		setColorsDisplay(toggleColorsDisplay(_id));
	}

	const toggleDelayDisplay = (_id) => {
		return (_id === "5f56a0263604913c339d863d") ? false : true; // Static
	}

	const toggleColorsDisplay = (_id) => {
		return (_id === "5f56a0263604913c339d8644" || _id === "5f56a0263604913c339d8645") ? false : true; // Rainbow, Rainbow Chase
	}

	const handleColorClick = (index) => {
		const updatedColors = [...colors];
		updatedColors[index].display = !colors[index].display;
		setColors(updatedColors);
	};

	const handleColorClose = (index) => {
		const updatedColors = [...colors];
		updatedColors[index].display = false;
		setColors(updatedColors);
	};

	const handleColorChange = (color,index) => {
		const updatedColors = [...colors];
		updatedColors[index].color = color.hex.slice(1);
		setColors(updatedColors);
	};

	const handleColorAdd = (index) => {
		let tempColors = [...colors];
		tempColors.splice(index,0,{...colors[index]});
		setColors(tempColors);
	}

	const handleColorDelete = (index) => {
		setColors([...colors.slice(0,index),...colors.slice(index+1,colors.length)])
	}

	const submitHandler = async (e) => {
		e.preventDefault();

		// toggleDelayDisplay(patternType)
		// toggleColorsDisplay(patternType)

		const lightStrandPatternData = {
			// _id: lightStrandPattern._id,
			name:					name,
			description:	description,
			brightness:		brightness,
			delay:				toggleDelayDisplay(patternType) ? delay : 10,
			colors:				toggleColorsDisplay(patternType) ? colors.map(color => { return color.color }) : ["FFFFFF"],
			type:					patternType,
		};

		try {
			await api.updateLightStrandPattern(lightStrandPattern._id,lightStrandPatternData);
			await api.updateLightStrand(
				props.match.params.lightStrandId,
				{
					pattern: lightStrandPattern._id
				}
			);
			props.history.push("/");
		} catch (error) {
			console.log("Error caught");
			console.log(error);

			// setEmailError(false);
			// setPasswordError(false);
			// switch(error.message) {
			// 	case "INVALID_USER": {
			// 		setEmailError(true);
			// 		break;
			// 	} case "INVALID_PASSWORD": {
			// 		setPasswordError(true);
			// 		// setPasswordError("The u is incorrect.");
			// 		break;
			// 	} default: {

			// 	}
			// }
		}
	}

	const onLightStrandSelect = (e) => {
		// console.log(e);
		// console.log(e.target.value);
		// props.history.push(`/lightStrands/${props.match.params.lightStrandId}/lightStrandPatterns/create`);
		props.history.push(`/lightStrands/${props.match.params.lightStrandId}/lightStrandPatterns/update/${e.target.value}`);
		// /lightStrands/5f76de06b6ff684ccee749dd/lightStrandPatterns/update/5f76de06b6ff684ccee749dc
	}

	const onCreateNewPattern = () => {
		props.history.push(`/lightStrands/${props.match.params.lightStrandId}/lightStrandPatterns/create`);
	}

	if (loading || !lightStrandPattern || !patternTypes || !props.userLightStrandPatterns)
		return <LoadingSpinner />;

	return (
		<div className="text-white">
			<div className="d-flex justify-content-center mb-5">
				<h1 className="text-white">Update LightStrand Pattern</h1>
			</div>

			<div className="form-row">
				<div className="form-group col-md-5 d-flex align-items-end">
				<label htmlFor="inputStartTime"></label>
					<button className="btn flex-fill btn-primary" onClick={onCreateNewPattern}>Create New Pattern</button>
				</div>

				<div className="form-group col-md-7">
					<label htmlFor="userLightStrandPatterns">My LightStrand Patterns</label>
					<select
						className="custom-select custom-select-sm"
						id="userLightStrandPatterns"
						value={props.match.params.lightStrandPatternId}
						onChange={onLightStrandSelect}
					>
					{
						props.userLightStrandPatterns.map(lightStrandPattern => (
							<option
								key={lightStrandPattern._id}
								value={lightStrandPattern._id}
							>
								{lightStrandPattern.name} - {lightStrandPattern.type.name}
							</option>
						))
					}
					</select>
				</div>
			</div>

			<div className="d-flex justify-content-center mt-5 mb-3">
				<h3 className="text-white">Selected Pattern</h3>
			</div>

			<form onSubmit={submitHandler}>
				<div className="form-row">
					<div className="form-group col-md-4">
						<label htmlFor="inputName">Name</label>
						<input type="text" className="form-control" id="inputName" defaultValue={name} onChange={onNameChange} />
					</div>
					<div className="form-group col-md-8">
						<label htmlFor="inputDescription">Description</label>
						<input type="text" className="form-control" id="inputDescription" defaultValue={description} onChange={onDescriptionChange} />
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputPatternType">Pattern Type</label>
						<div className="btn-group d-block btn-group-toggle flex-wrap" id="inputPatternType" data-toggle="buttons">
							{
								patternTypes.map(pattern => (

									pattern.value === lightStrandPattern.type.value ?
									<label key={pattern.value} className="btn btn-dark px-2 rounded active">
										<input type="radio" name="options" id={pattern.value} onClick={() => onPatternTypeChange(pattern._id)} defaultChecked/><small>{pattern.name}</small>
									</label>
									:
									<label key={pattern.value} className="btn btn-dark px-2 rounded">
										<input type="radio" name="options" id={pattern.value} onClick={() => onPatternTypeChange(pattern._id)} /><small>{pattern.name}</small>
									</label>
								))
							}
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputBrightness">Brightness</label>
						<div className="d-flex justify-content-left align-items-center">
						<input type="range" className="custom-range inline-flex flex-start" min="0" max="255" step="5" id="inputBrightness" value={brightness} onChange={onBrightnessChange}/>
						<span className="font-weight-bold text-white inline-flex pl-2">{brightness}</span>
						</div>
					</div>
				</div>

				{
				delayDisplay
				&&
				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputDelay">Delay</label>
						<div className="d-flex justify-content-left align-items-center">
						<input type="range" className="custom-range inline-flex flex-start" min="10" max="1000" step="10" id="inputDelay" value={delay} onChange={onDelayChange}/>
						<span className="font-weight-bold text-white inline-flex pl-2">{delay}&nbsp;ms</span>
						</div>
					</div>
				</div>
				}

				{
				colorsDisplay
				&&
				<div className="form-row">
					<div className="form-group col-md-12">
						<label htmlFor="inputPatternType">Colors</label>
						<div className="btn-group btn-group-sm d-flex justify-content-between flex-wrap p-1" id="inputPatternType" data-toggle="buttons" style={{background: "#fff",borderRadius: "2px"}}>
							{/*<CirclePicker colors={["#ffffff","#000000"]} onChange={onColorChange} onSwatchHover={(color,event) => console.log(color,event)} />*/}
							{
								colors.map((color,index) => (
									<ColorPicker
										key={index}
										index={index}
										color={color}
										handleColorClick={handleColorClick}
										handleColorClose={handleColorClose}
										handleColorChange={handleColorChange}
										handleColorAdd={handleColorAdd}
										handleColorDelete={handleColorDelete}
										canAdd={colors.length < MAX_COLORS ? true : false}
										canDelete={colors.length > 1 ? true : false}
									/>
								))
							}
						</div>
					</div>
				</div>
				}

				<div className="mt-3">
					<button type="submit" className="btn btn-primary">Update</button>
				</div>

			</form>

		</div>
	);
};

// export default LightStrandPattern;

function mapStateToProps(state) {
	return {
		// error:						state.error,
		userLightStrandPatterns:	state.lightStrand.userLightStrandPatterns
		// lightStrand:	state.lightStrand.selectedLightStrand,
	};
}

export default connect(
	mapStateToProps,
	{
		getUserLightStrandPatterns,
		// getLightStrand,
		// logout
	}
)(LightStrandPatternUpdate);