// Authentication
export const AUTHENTICATE							= "AUTHENTICATE";
export const LOGOUT										= "LOGOUT";

// LightStrand
export const GET_USER_LIGHTSTRANDS			= "GET_USER_LIGHTSTRANDS";
export const GET_SELECTED_LIGHTSTRAND		= "GET_SELECTED_LIGHTSTRAND";
export const GET_UNCLAIMED_LIGHTSTRANDS	= "GET_UNCLAIMED_LIGHTSTRANDS";

// LightStrand Patterns
export const GET_USER_LIGHTSTRAND_PATTERNS	= "GET_USER_LIGHTSTRAND_PATTERNS";

// Error
export const ADD_ERROR			="ADD_ERROR";
export const REMOVE_ERROR	="REMOVE_ERROR";