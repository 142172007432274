import * as actions from "./index.js";

const DEBUG = process.env.REACT_APP_DEBUG !== undefined;

export const addError = (error) => async (dispatch) => {
	if (DEBUG) console.log(`[REDUX] addError:\n\terror: ${JSON.stringify(error)}`);

	dispatch({
		type:	actions.ADD_ERROR,
		id:		error,
	});
};

export const removeError = () => async (dispatch) => {
	if (DEBUG) console.log(`[REDUX] removeError`);

	dispatch({
		type: actions.REMOVE_ERROR
	});
};
