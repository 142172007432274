import React,{ useState,useEffect } from "react";

import {
	connect,
} from "react-redux";

import { logout } from "../store/actions/authentication";

import { getUserLightStrands } from "../store/actions/lightStrand";

import LightStrandCard from "./LightStrandCard";
import LoadingSpinner from "./UI/LoadingSpinner";

const Home = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	},[]);

	const { getUserLightStrands }	= props;

	const [loading,setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		try {
			getUserLightStrands();
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	},[setLoading,getUserLightStrands]);

	if (props.error.id) {
		if (props.error.id === "UNAUTHORIZED")
			props.logout();
		return (
			<div className="text-center">
				<p className="text-white">An error has occurred</p>
				<p className="text-white">{ props.error.id }: { props.error.message }</p>
				<p className="text-white">Please try again...</p>
			</div>
		);
	}

	if (loading)
		return <LoadingSpinner />;

	if (!props.userLightStrands.length) {
		return (
			<React.Fragment>
				<p className="text-white">No LightStrands...</p>
			</React.Fragment>
		);
	}

	return (
		<div>
			<div className="d-flex justify-content-center mb-5">
				<h1 className="text-white">My LightStrands</h1>
			</div>
			<div className="">
				{
					props.userLightStrands.map(lightStrand => (
						<LightStrandCard
							key={lightStrand._id}
							lightStrand={lightStrand}
						/>
					))
				}
			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		error:						state.error,
		userLightStrands:	state.lightStrand.userLightStrands,
	}),
	{
		getUserLightStrands,
		logout
	}
)(Home);