import * as actions from "./index.js";

import * as api from "../../api";

import * as errorActions from "./error";

const DEBUG = process.env.REACT_APP_DEBUG !== undefined;

let timer;

export const authenticate = (userId,token,expirationTime) => dispatch => {
	if (DEBUG) console.log(`[REDUX] authenticate(userId=${userId},token=${token},expirationTime=${expirationTime} ms)`);

	api.setAuthorizationToken(token);

	dispatch(setLogoutTimer(expirationTime));

	dispatch({
		type:						actions.AUTHENTICATE,
		authenticated:	!!userId && !!token,
		userId:					userId,
		token:					token,
	});
};

export const login = (email,password) => async dispatch => {
	if (DEBUG) console.log(`[REDUX] login(email=${email},password=${password})`);

	let response
	try {
		response = await api.login(email,password);
	} catch (error) {
		dispatch(errorActions.addError(error.message));
		return false;
	}

	if (response && response.error) {
		console.log("Error in login action");
		console.log(response.error);
		dispatch(
			authenticate(
				null,null,null
			)
		);

		dispatch(errorActions.addError(response.error));
		return false;
	}

	localStorage.setItem(
		"userData",
		JSON.stringify({
			token:								response.token,
			userId:								response.userId,
			tokenExpirationDate:	new Date(new Date().getTime() + (+response.expiresIn))
		})
	);

	dispatch(
		authenticate(
			response.userId,
			response.token,
			response.expiresIn
		)
	);

	dispatch(
		errorActions.removeError()
	);
	return true;
};

export const signup = (email,password) => async dispatch => {
	if (DEBUG) console.log(`[REDUX] signup(email=${email},password=${password})`);

	let response;
	try {
		response = await api.signup(email,password);
	} catch (error) {
		dispatch(errorActions.addError(error.message));
		return false;
	}

	if (response && response.error) {
		console.log("Error in signup action");
		console.log(response.error);
		dispatch(
			authenticate(null,null,null)
		);

		dispatch(errorActions.addError(response.error));
		return false;
	}

	localStorage.setItem(
		"userData",
		JSON.stringify({
			token:								response.token,
			userId:								response.userId,
			tokenExpirationDate:	new Date(new Date().getTime() + (+response.expiresIn))
		})
	);

	dispatch(
		authenticate(
			response.userId,
			response.token,
			response.expiresIn
		)
	);

	dispatch(errorActions.removeError());
	return true;
};

export const logout = () => async (dispatch) => {
	if (DEBUG) console.log(`[REDUX] logout()`);

	clearLogoutTimer();

	localStorage.clear();

	dispatch(errorActions.removeError());

	api.setAuthorizationToken(false);

	dispatch({
		type:	actions.LOGOUT
	});
};

const clearLogoutTimer = () => {
	if (timer)
		clearTimeout(timer);
};

const setLogoutTimer = expirationTime => dispatch => {
	timer = setTimeout(
		() => {
			dispatch(logout());
		},
		expirationTime
	);
};