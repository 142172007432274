import React from "react";

import {
	connect
} from "react-redux";

import { removeError } from "../store/actions/error";

import Login from "../components/user/Login";
import Signup from "../components/user/Signup";

const Authentication = (props) => {
	props.history.listen(() => {
		props.removeError();
	});

	return (
		<div className="align-self-center">
		{
			props.location.pathname.toLowerCase() === "/login"
			&&
			<Login
				history={props.history}
			/>
		}
		{
			props.location.pathname.toLowerCase() === "/signup"
			&&
			<Signup
				history={props.history}
			/>
		}
		</div>
	);
};

export default connect(
	(state) => ({

	}),
	{
		removeError,
	}
)(Authentication);