import { combineReducers } from "redux";

import authentication from "./authentication";
import lightStrand from "./lightStrand";
import error from "./error";

const rootReducer = combineReducers({
	authentication,
	lightStrand,
	error
});

export default rootReducer;