import * as actions from "./index.js";

import * as api from "../../api";

import * as errorActions from "./error";

const DEBUG = process.env.REACT_APP_DEBUG !== undefined;

export const getLightStrand = (_id) => async (dispatch,getState) => {
	if (DEBUG) console.log(`[REDUX] getLightStrand(_id=${_id})`);

	const selectedLightStrand = getState().lightStrand.userLightStrands.find(lightStrand => lightStrand._id === _id);

	dispatch({
		type:									actions.GET_SELECTED_LIGHTSTRAND,
		selectedLightStrand:	selectedLightStrand
	});
};

// createAndSetLightStrandPattern
export const createLightStrandPattern = (lightStrandPattern) => async (dispatch,getState) => {
	if (DEBUG) console.log(`[REDUX] createLightStrandPattern(lightStrandPattern=${lightStrandPattern})`);

	let response;
	try {
		response = api.createLightStrandPattern({
			...lightStrandPattern,
			user:	getState().authentication.userId
		});
	} catch (error) {
		console.log(error);
		return dispatch(errorActions.addError(error.message));
	}
	// console.log("hit");
	if (response && response.error) {
		console.log("Error in action");
		console.log(response.error);
		// throw new Error(error);
		// return new Error("test")

		// dispatch({
		// 	type:						actions.GET_USER_LIGHTSTRAND_PATTERNS,
		// 	// authenticated:	false,
		// });
	}
};


export const claimLightStrandPattern = (userId,lightStrandPatternId) => async (dispatch) => {
	if (DEBUG) console.log(`[REDUX] claimLightStrandPattern(userId=${userId},lightStrandPatternId=${lightStrandPatternId})`);

	let response;

	try {
		response = await api.claimLightStrandPattern(userId,lightStrandPatternId);

		dispatch(errorActions.removeError());
	} catch (error) {
		return dispatch(errorActions.addError(error.message));
	}

	if (response && response.error) {
		console.log("Error in action");
		console.log(response.error);
		// throw new Error(error);
		// return new Error("test")

		// dispatch({
		// 	type:						actions.AUTHENTICATE,
		// 	authenticated:	false,
		// });
	}
	
	// TODO: Maybe dispatch refreshing the user's patterns
	// dispatch({
	// 	type:										actions.GET_UNCLAIMED_LIGHTSTRANDS,
	// 	unclaimedLightStrands:	response
	// });
};

export const claimLightStrand = (userId,lightStrandId) => async (dispatch) => {
	if (DEBUG) console.log(`[REDUX] claimLightStrand(userId=${userId},lightStrandId=${lightStrandId})`);

	let response;
	// console.log("hit");
	try {
		// console.log("hit");
		response = await api.claimLightStrand(userId,lightStrandId);
		// console.log("hit");
		// dispatch({})
		dispatch(errorActions.removeError());
	} catch (error) {
		// return handleError(dispatch,error);
		return dispatch(errorActions.addError(error.message));
	}

	if (response && response.error) {
		console.log("Error in action");
		console.log(response.error);
		// throw new Error(error);
		// return new Error("test")

		// dispatch({
		// 	type:						actions.AUTHENTICATE,
		// 	authenticated:	false,
		// });
	}
	// console.log("hit");

	// localStorage.setItem(
	// 	"unclaimedLightStrands",
	// 	JSON.stringify(response)
	// );

	// dispatch({
	// 	type:										actions.GET_UNCLAIMED_LIGHTSTRANDS,
	// 	unclaimedLightStrands:	response
	// });
};

export const getUnclaimedLightStrands = () => async (dispatch) => {
	if (DEBUG) console.log(`[REDUX] getUnclaimedLightStrands()`);

	let response;

	try {

		response = await api.getUnclaimedLightStrands();

		dispatch(errorActions.removeError());
	} catch (error) {
		// return handleError(dispatch,error);
		return dispatch(errorActions.addError(error.message));
	}
	// console.log("hit");
	if (response && response.error) {
		console.log("Error in action");
		console.log(response.error);
		// throw new Error(error);
		// return new Error("test")

		// dispatch({
		// 	type:						actions.AUTHENTICATE,
		// 	authenticated:	false,
		// });
	}

	dispatch({
		type:										actions.GET_UNCLAIMED_LIGHTSTRANDS,
		unclaimedLightStrands:	response
	});
};

export const getUserLightStrands = () => async (dispatch,getState) => {
	if (DEBUG) console.log(`[REDUX] getUserLightStrands()`);

	let response;
	try {
		response = await api.getUserLightStrands(getState().authentication.userId);
		// dispatch({})
		dispatch(errorActions.removeError());
	} catch (error) {
		// return handleError(dispatch,error);
		return dispatch(errorActions.addError(error.message));
	}
	if (response && response.error) {
		console.log("Error in action");
		console.log(response.error);
		// throw new Error(error);
		// return new Error("test")

		// dispatch({
		// 	type:						actions.AUTHENTICATE,
		// 	authenticated:	false,
		// });
	}

	localStorage.setItem(
		"userLightStrands",
		JSON.stringify(response)
	);

	dispatch({
		type:							actions.GET_USER_LIGHTSTRANDS,
		userLightStrands:	response
	});
};

export const getUserLightStrandPatterns = () => async (dispatch,getState) => {
	if (DEBUG) console.log(`[REDUX] getUserLightStrandsPatterns()`);

	let response;
	try {
		response = await api.getUserLightStrandPatterns(getState().authentication.userId);
		// dispatch({})
		dispatch(errorActions.removeError());
	} catch (error) {
		// return handleError(dispatch,error);
		return dispatch(errorActions.addError(error.message));
	}
	if (response && response.error) {
		console.log("Error in action");
		console.log(response.error);
		// throw new Error(error);
		// return new Error("test")

		// dispatch({
		// 	type:						actions.AUTHENTICATE,
		// 	authenticated:	false,
		// });
	}

	dispatch({
		type:							actions.GET_USER_LIGHTSTRAND_PATTERNS,
		userLightStrandPatterns:	response
	});
};